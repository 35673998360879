import React from "react";
import logo from "../assets/logo-turbo2.svg";

function Footer() {
  return (
    <footer className="bg-[#2E2F31] text-white py-16 flex flex-col items-center justify-center w-full">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="flex items-center mb-10 md:mb-0">
          <img src={logo} alt="Logo" className="h-10 mr-0 md:mr-10 w-40" />
        </div>
        <div className="flex flex-wrap gap-6 md:text-lg font-medium justify-center">
          <a
            href="https://t.me/MURBOonSolana"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
          </a>
          <a
            href="https://x.com/MagaTurbosol"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://dexscreener.com/yourlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dexscreener
          </a>
          <a
            href="https://birdeye.so/yourlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Birdeye
          </a>
          <a
            href="https://solscan.io/yourlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Solscan
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
