import poo from "../assets/poo.webp";
import berri from "../assets/berri.png";

function Services() {
  return (
    <div className="flex flex-col gap-8 w-[90%] mt-0 justify-center max-w-screen-md p-4 mb-16 mx-auto bg-[#FEE564] z-10">
      <h1 className="text-5xl uppercase md:text-6xl text-start font-black leading-1 drop-shadow-2xl text-red-600 py-4 md:py-8 rounded-lg">
        $CONKER <br className="md:hidden" />{" "}
        <span className="text-4xl font-medium md:font-bold">On Solana</span>
      </h1>

      <p className=" text-xl md:text-2xl font-light ">
        Remember the chaotic, hilarious brawls of Conker's Bad Fur Day?
        <span className="text-red-600 font-semibold">$CONKER</span> brings that
        wild energy back as a memecoin built for the degenerate apes of today.
        This ain't your average memecoin.{" "}
        <span className="text-red-600 font-semibold">$CONKER</span> lets you
        relive the nostalgia of a true classic, fueled by the same kind of
        antics that made the original game legendary.
      </p>
      <img src={poo} className="rounded-xl opacity-0 md:opacity-100" />
      <img
        src={berri}
        className="absolute left-0 bottom-0 -translate-x-2 translate-y-24"
      />
    </div>
  );
}
export default Services;
