import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import bg from "../assets/conkers-bg.png";
import murbo from "../assets/murbo.png";

function Header() {
  return (
    <header className="relative w-full flex flex-col justify-center items-center">
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        src={bg}
        className="w-[90%] max-w-2xl rounded-3xl my-10"
      />
      {/* <motion.div className="relative flex justify-center items-center w-full">
        <img src={murbo} className="w-[70%] mt-6 md:mt-10 max-w-2xl" />
      </motion.div> */}
      <a
        href="https://pump.fun/BQS7RpfiBE8EobMe4pZ634vmZvcd2qS77KASm2r8Fwsu"
        target="_blank"
        className="bg-red-500 text-white text-lg md:text-3xl font-bold rounded-full py-4 w-[90%] text-center max-w-md mb-2"
      >
        Buy on Pump.fun 💸
      </a>
      <div className="flex flex-row w-[90%] text-center max-w-md  justify-between items-center gap-2 mb-10">
        <a
          href="https://telegram.org/"
          target="_blank"
          className="w-1/2 text-center bg-white rounded-full py-3"
        >
          Telegram
        </a>
        <a
          href="https://x.com/conkeronsol/"
          target="_blank"
          className="w-1/2 text-center bg-white rounded-full py-3"
        >
          Twitter
        </a>
      </div>
    </header>
  );
}

export default Header;
