import React, { useState } from "react";
import { contract } from "../constants";
import { motion, AnimatePresence } from "framer-motion";
import money from "../assets/green.gif";
import conk from "../assets/conker1.webp";
import bg from "../assets/conker-bg.jpeg";
function About() {
  const [copySuccess, setCopySuccess] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const handleCopyText = async () => {
    try {
      await navigator.clipboard.writeText(contract);
      setCopySuccess("Contract Copied!");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    }
  };
  return (
    <div className="flex mx-auto flex-col my-10 gap-4 text-center justify-center items-center text-stone-800">
      <img src={conk} className="w-full rounded-lg max-w-2xl z-10" />

      <img
        src={money}
        className="w-[90%] rounded-lg max-w-2xl z-10 drop-shadow-lg "
      />
      <img
        src={bg}
        className="w-full rounded-lg absolute left-0 top-0 -z-1 opacity-25 mt-20 md:mt-24"
      />
    </div>
  );
}
export default About;
