import React, { useState, useCallback } from "react";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import AudioContext from "./components/AudioContext";
import bg from "./assets/conker-bg.jpeg";
import video from "./assets/conkervid.mp4";
import logo from "./assets/logo-conker.png";

function App() {
  const [isEntered, setIsEntered] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleEnter = () => {
    setIsEntered(true);
    setIsPlaying(true);
  };

  const toggleAudio = useCallback(() => {
    setIsPlaying((prev) => !prev);
  }, []);
  return (
    <AudioContext.Provider value={{ isPlaying, toggleAudio }}>
      {!isEntered && (
        <div className="fixed inset-0 bg-black flex items-center justify-center z-50">
          <div className="z-10 w-fit h-full">
            <img
              src={logo}
              className="object-contain w-[80%] mt-16 md:mt-8 max-w-md absolute left-[50%] translate-x-[-50%]"
            />
            <button
              onClick={handleEnter}
              className="text-xl md:text-2xl w-full max-w-xs absolute cursor-pointer z-50 left-[50%] translate-x-[-50%] bottom-16 md:bottom-24 font-bold py-4 px-10 md:px-24 bg-orange-500 border-white border-[3px] text-white rounded-xl hover:bg-orange-400 hover:cursor-pointer transition duration-300"
            >
              Enter Site
            </button>
            <video
              className="min-h-[100vh] h-full"
              src={video}
              muted
              autoPlay
              loop
            ></video>
            {/* <img
              className="min-h-[100vh] h-full object-contain"
              src={video}
              muted
              autoPlay
              loop
            ></img> */}
          </div>

          <img
            src={bg}
            className="hidden md:flex w-full h-full object-cover absolute top-0 left-0 -z-1 opacity-15"
          />
        </div>
      )}
      {isEntered && (
        <>
          <Navbar />
          <Header />
          <Main />
          {/* <Footer /> */}
        </>
      )}
    </AudioContext.Provider>
  );
}

export default App;
